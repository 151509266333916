import axios from 'axios'
import {
  Message
} from 'element-ui' // 2.0消息提示框
import router from '@/routers';
import store  from '@/stores';


// 创建实例
const service = axios.create({
  baseURL: "/", // 当前环境地址变量
  timeout: 10000, // 请求超时时间
  headers: { // 设置默认请求头
    'Content-Type': 'application/json;charset=UTF-8'
  },
})

// 请求拦截
service.interceptors.request.use(
  config => {
    const token = localStorage.getItem('playOne-store-token')
    if (token) {
      config.headers['Authorization'] = token;
    }
    // 假设我们有一个数组，包含需要显示加载状态的 URL 模式
    const urlsToShowLoading = ['/apm/proxy/recharge/findStatusById'];

    // 检查请求的 URL 是否在需要显示加载状态的 URL 模式中
    const shouldShowLoading = urlsToShowLoading.some(url => config.url.includes(url));
    if (shouldShowLoading==false) {
      store.commit('showLoading'); // 开启loading
    }

    return config
  },
  err => {
    store.commit('hideLoading'); //关闭loading
    return Promise.reject(err);
  }
);

// 响应拦截
service.interceptors.response.use(
  res => {
    store.commit('hideLoading'); //开启loading
    return res;
  },
  err => {
    store.commit('hideLoading'); //关闭loading
    Message.error("服务器未启动");
    return Promise.reject(err);
  }
);

// 错误提示
const errorMsg = _.throttle((message) => {
  Message.error(message)
}, 1000, {
  trailing: false
})

// 过期登录防抖 
const restartMsg = _.throttle(() => {
  Message.error("登录已过期，请重新登录");
  router.push('/mine/login');
}, 1000, {
  trailing: false
})

// 请求函数
function request(method, url, data) {
  data = method === 'get' || method === 'delete' ? {
    params: data
  } : data
  return new Promise((resolve, reject) => {
    service[method](url, data)
      .then(res => {
        if (res.data.isSuccess == "no") {
          errorMsg(res.data.message)
        } else if (res.data.isSuccess == "restart") {
          restartMsg()
        }
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      })
  })
}
export default request
// import axios from 'axios'
// import {
//   Message
// } from 'element-ui' // 2.0消息提示框
// import router from '@/routers';
// import store  from '@/stores';
// import _ from 'lodash'; // 导入lodash库

// // 创建实例
// const service = axios.create({
//   baseURL: "/", // 当前环境地址变量
//   timeout: 10000, // 请求超时时间
//   headers: { // 设置默认请求头
//     'Content-Type': 'application/json;charset=UTF-8'
//   },
// })

// // 请求队列
// const pendingRequests = new Map();

// // 请求拦截
// service.interceptors.request.use(
//   config => {
//     const token = localStorage.getItem('playOne-store-token')
//     if (token) {
//       config.headers['Authorization'] = token;
//     }
//     // 假设我们有一个数组，包含需要显示加载状态的 URL 模式
//     const urlsToShowLoading = ['/apm/proxy/recharge/findStatusById'];

//     // 检查请求的 URL 是否在需要显示加载状态的 URL 模式中
//     const shouldShowLoading = urlsToShowLoading.some(url => config.url.includes(url));
//     if (shouldShowLoading==false) {
//       store.commit('showLoading'); // 开启loading
//     }

//     // 生成请求的唯一标识
//     const requestKey = `${config.url}&${config.method}`;
//     if (pendingRequests.has(requestKey)) {
//       Message.error("请勿重复提交");
//       return Promise.reject(new Error("请勿重复提交"));
//     }
//     pendingRequests.set(requestKey, config);

//     return config
//   },
//   err => {
//     store.commit('hideLoading'); //关闭loading
//     return Promise.reject(err);
//   }
// );

// // 响应拦截
// service.interceptors.response.use(
//   res => {
//     store.commit('hideLoading'); //开启loading

//     // 移除请求队列中的请求
//     const requestKey = `${res.config.url}&${res.config.method}`;
//     pendingRequests.delete(requestKey);

//     return res;
//   },
//   err => {
//     store.commit('hideLoading'); //关闭loading

//     // 移除请求队列中的请求
//     const requestKey = `${err.config.url}&${err.config.method}`;
//     pendingRequests.delete(requestKey);

//     Message.error("服务器未启动");
//     return Promise.reject(err);
//   }
// );

// // 错误提示
// const errorMsg = _.throttle((message) => {
//   Message.error(message)
// }, 1000, {
//   trailing: false
// })

// // 过期登录防抖 
// const restartMsg = _.throttle(() => {
//   Message.error("登录已过期，请重新登录");
//   router.push('/mine/login');
// }, 1000, {
//   trailing: false
// })

// // 请求函数
// function request(method, url, data) {
//   data = method === 'get' || method === 'delete' ? {
//     params: data
//   } : data
//   return new Promise((resolve, reject) => {
//     service[method](url, data)
//       .then(res => {
//         if (res.data.isSuccess == "no") {
//           errorMsg(res.data.message)
//         } else if (res.data.isSuccess == "restart") {
//           restartMsg()
//         }
//         resolve(res.data);
//       })
//       .catch(err => {
//         reject(err);
//       })
//   })
// }
// export default request