<template>
  <div id="app">
    <router-view></router-view>
    <Loading v-show="LOADING"></Loading>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Loading from "@/components/loading";
export default {
  components: { Loading },
  computed: {
    ...mapState(["LOADING"]),
  },
};
</script>
<style lang="scss" scoped>
input[aria-hidden=true] {
	display: none !important;
}
/deep/.el-radio-group{
  display: inline-block !important;
}
</style>