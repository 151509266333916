import Vue from 'vue';
import App from './App.vue';
import '@/utils/rem';
import 'animate.css';
import '@/assets/styles/reset.css';
import '@/assets/styles/elementui.css';
import '@/assets/styles/iconfont/iconfont.css';
import router from '@/routers';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/styles/onePlay/theme/index.css';
import _ from 'lodash';
import '@/utils/directives';
import store from  '@/stores';
import Vant from 'vant';
import 'vant/lib/index.css';
import DatePicker from '@/components/date-picker'
import Table from '@/components/echarts/child/table'


// 高德地图安全密钥
window._AMapSecurityConfig = {
  securityJsCode:'6f54059bf696abfbd8bbd8a590f71730',  
}
Vue.prototype.$store = store
Vue.config.productionTip = false;
Vue.component('DatePicker', DatePicker)
Vue.component('Table', Table)
Vue.use(_)
Vue.use(ElementUI);
Vue.use(Vant);
new Vue({
  router,store,
  render: h => h(App),
}).$mount('#app')